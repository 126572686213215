import React from 'react'
import Layout from '../components/Layout'
import * as styles from "../styles/contact.module.css"
// import { Card, Container, Row, Col } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhoneAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
// import GoogleMap from '../components/GoogleMap'

import Iframe from 'react-iframe'


// import { Card, CardAction, CardContent } from '@mui/material/Card';

export default function contact({ data }) {
    console.log(data);
    return (

        
        <div>
        <h1 className={styles.header}>Contact Us</h1>
        <Layout>
            
                
                <div className={styles.cardContainer}>
                    <div className={styles.card}>
                        <h2><FontAwesomeIcon icon={faMapMarkerAlt} style={{color:'#726ea3' }}/></h2>
                        <div className={styles.info}>
                            <h3>Operational Office</h3>
                            <p>1005 Hemby Ridge Ln, Morrisville, NC 27560.</p>
                        </div>
                    </div>
                    
                    <div className={styles.card} >
                        <h2><FontAwesomeIcon icon={faPhoneAlt} style={{color:'#726ea3' }}/></h2>
                        <div className={styles.info}>
                            <h3>Call Us</h3>
                            <a href='tel:+1 (984)-377-9296' style={{textDecoration:"none"}}><p>+1 (984)-377-9296</p></a>
                        </div>
                    </div>
                    
                    <div className={styles.card}>
                        <h2><FontAwesomeIcon icon={faEnvelope} style={{color:'#726ea3' }}/></h2>
                        <div className={styles.info}>
                            <h3>E-mail Us</h3>
                            <a href='mailto:admin@rivalsol.com' style={{textDecoration:"none"}}><p>admin@rivalsol.com</p></a>
                        </div>
                    </div>
                </div>

                {/* <GoogleMap /> */}
                
            
            <div className='maps'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3233.3259993308984!2d-78.8543213849021!3d35.8655271274502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89acee31ab93a487%3A0x918538e73cf880b8!2s1005%20Hemby%20Ridge%20Ln%2C%20Morrisville%2C%20NC%2027560%2C%20USA!5e0!3m2!1sen!2sin!4v1642674294016!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy"></iframe>
            </div>
        </Layout>
        </div>
    )
}

